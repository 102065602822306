<template>
  <div class="personal-info-content">
    <van-cell-group inset>
      <van-cell title="头像">
        <template #right-icon>
          <img class="info-head" src="https://hmb-public-1302344794.cos.ap-nanjing.myqcloud.com/inclusive-health-insurance/img/relation-icon2.png"
            alt="">
        </template>
      </van-cell>
      <van-cell title="姓名">
        <template #right-icon>
          <span class="cell-value">{{userInfo.realName}}</span>
        </template>
      </van-cell>
      <van-cell title="手机号">
        <template #right-icon>
          <span class="cell-value">{{userInfo.mobile}}</span>
        </template>
      </van-cell>
    </van-cell-group>
    <!-- <div class="footer">
      <div class="footer-btn">退出登录</div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: '',
  data () {
    return {
      userInfo: {
        realName: '--',
        mobile: '--'
      }
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  created () {
  },
  mounted () {
    this.userInfo = this.getUserInfo
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.personal-info-content {
  font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif";
  min-height: 100vh;
  background-color: #f7f7f7;
  .info-head {
    width: 75px;
    height: 75px;
  }
  /deep/.van-cell-group {
    margin: 0;
  }
  /deep/.van-cell__title {
    display: flex;
    align-items: center;
  }
  .cell-value {
    height: 75px;
    line-height: 75px;
  }
  .footer {
    position: fixed;
    bottom: 60px;
    left: 35px;
    right: 0;
    .footer-btn {
      font-size: 32px;
      color: #ff0904;
      padding: 25px 0;
      width: 680px;
      border: 1px solid #ff0904;
      border-radius: 50px;
      text-align: center;
    }
  }
}
</style>
